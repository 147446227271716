<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.estates") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="estates"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="name"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/estates/${id}`)"
      >
        <template #header>
          <span class="flex justify-end gap-2"
            ><form-button
              v-if="company.fivaldiId"
              :command="syncFivaldi"
              :icon="ArrowDownTrayIcon"
              id="header-fivaldi-sync-button"
              label="fivaldi_sync"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary" />
            <form-button
              :command="() => router.push('/estates/create')"
              :icon="PlusIcon"
              id="header-estate-create-button"
              label="create_estate"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
export default {
  components: {
    ArrowDownTrayIcon,
    Breadcrumb,
    CustomTable,
    FormButton,
    PlusIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "address",
        label: "address",
      },
      {
        key: "postalCode",
        label: "postal_code",
      },
      {
        key: "city",
        label: "city",
      },
      {
        key: "businessId",
        label: "business_id",
      },
      {
        key: "identifier",
        label: "identifier",
      },
    ]);
    const searchFields = ref([
      "name",
      "address",
      "postalCode",
      "city",
      "identifier",
    ]);
    const sortFields = ref([
      "address",
      "city",
      "name",
      "postalCode",
      "identifier",
    ]);

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    onMounted(() => {
      if (!estates.value.length)
        store.dispatch("estate/getEstates", user.value.companyId);
    });

    return {
      ArrowDownTrayIcon,
      company,
      estates,
      fields,
      PlusIcon,
      router,
      searchFields,
      sortFields,
      syncFivaldi: () => store.dispatch("estate/syncFivaldi"),
      t,
      waiting,
    };
  },
};
</script>
